function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

(function () {
  var track, valueForTag, validateTag, consumeTag, isConstTag;

  if (false) {
    var validator = Ember.__loader.require('@glimmer/validator');

    track = validator.track;
    valueForTag = validator.valueForTag || validator.value;
    validateTag = validator.validateTag || validator.validate;
    consumeTag = validator.consumeTag || validator.consume;
    isConstTag = validator.isConstTag;
  } else if (true) {
    var metal = Ember.__loader.require('@ember/-internals/metal');

    var reference = Ember.__loader.require('@glimmer/reference');

    track = metal.track;
    consumeTag = metal.consume;
    valueForTag = reference.value;
    validateTag = reference.validate;
    isConstTag = reference.isConstTag;
  } else if (true
  /* DEBUG */
  ) {
    throw new Error('Attempted to use cache polyfill with unsupported Ember version');
  }

  var Cache = function Cache(fn) {
    _classCallCheck(this, Cache);

    _defineProperty(this, "__lastValue", void 0);

    _defineProperty(this, "__tag", void 0);

    _defineProperty(this, "__snapshot", -1);

    this.__fn = fn;
  };

  Ember._createCache = function createCache(fn) {
    return new Cache(fn);
  };

  Ember._cacheGetValue = function getValue(cache) {
    if (true
    /* DEBUG */
    ) {
      (true && !(cache instanceof Cache) && Ember.assert("getValue() can only be used on an instance of a cache created with createCache(). Called with: ".concat(String(cache)), cache instanceof Cache));
    }

    var tag = cache.__tag,
        snapshot = cache.__snapshot,
        fn = cache.__fn;

    if (tag === undefined || !validateTag(tag, snapshot)) {
      tag = track(function () {
        return cache.__lastValue = fn();
      });
      cache.__tag = tag;
      cache.__snapshot = valueForTag(tag);
      consumeTag(tag);
    } else {
      consumeTag(tag);
    }

    return cache.__lastValue;
  };

  Ember._cacheIsConst = function isConst(cache) {
    if (true
    /* DEBUG */
    ) {
      (true && !(cache instanceof Cache) && Ember.assert("isConst() can only be used on an instance of a cache created with createCache(). Called with: ".concat(String(cache)), cache instanceof Cache));
      (true && !(cache.__tag) && Ember.assert("isConst() can only be used on a cache once getValue() has been called at least once. Called with cache function:\n\n".concat(String(cache.__fn)), cache.__tag));
    }

    return isConstTag(cache.__tag);
  };
})();