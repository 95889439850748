/* eslint-disable no-undef */
/* eslint-disable max-len, quotes */
window.deprecationWorkflow = window.deprecationWorkflow || {};
window.deprecationWorkflow.config = {
  workflow: [
    { handler: 'silence', matchId: 'ember-load-initializers.legacy-shims' },
    {
      handler: 'silence',
      matchId: 'ember-views.curly-components.jquery-element',
    },
    { handler: 'silence', matchId: 'ember-component.send-action' },
    { handler: 'silence', matchId: 'computed-property.override' },
    { handler: 'silence', matchId: 'ember.globals-resolver' },
    { handler: 'silence', matchId: 'ember-qunit.deprecate-legacy-apis' },
  ],
};
