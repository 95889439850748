define("ember-event-helpers/helpers/prevent-default", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.preventDefault = preventDefault;

  function preventDefault(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 1),
        handler = _ref2[0];

    (true && !(!handler || typeof handler === 'function') && Ember.assert("Expected '".concat(handler, "' to be a function, if present."), !handler || typeof handler === 'function'));
    return function (event) {
      (true && !(event && typeof event.preventDefault === 'function') && Ember.assert("Expected '".concat(event, "' to be an Event and have a 'preventDefault' method."), event && typeof event.preventDefault === 'function'));
      event.preventDefault();
      if (handler) handler(event);
    };
  }

  var _default = Ember.Helper.helper(preventDefault);

  _exports.default = _default;
});