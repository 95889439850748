define("ember-intl/services/intl", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/toConsumableArray", "@babel/runtime/helpers/esm/typeof", "ember-intl/-private/formatters", "ember-intl/-private/utils/is-array-equal", "ember-intl/-private/utils/normalize-locale", "ember-intl/-private/utils/get-dom", "ember-intl/-private/utils/hydrate", "ember-intl/-private/store/container"], function (_exports, _slicedToArray2, _defineProperty2, _toConsumableArray2, _typeof2, _formatters, _isArrayEqual, _normalizeLocale, _getDom, _hydrate, _container) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
  function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
  var _default = _exports.default = Ember.Service.extend(Ember.Evented, {
    /** @public **/
    formats: null,
    /**
     * Returns an array of registered locale names
     *
     * @property locales
     * @public
     */
    locales: Ember.computed.readOnly('_translationContainer.locales'),
    /** @public **/
    locale: Ember.computed('_locale', {
      set: function set(_, localeName) {
        var _this = this;
        var proposed = Ember.makeArray(localeName).map(_normalizeLocale.default);
        if (!(0, _isArrayEqual.default)(proposed, this._locale)) {
          Ember.set(this, '_locale', proposed);
          Ember.run.cancel(this._timer);
          this._timer = Ember.run.next(function () {
            _this.trigger('localeChanged');
            _this._updateDocumentLanguage(_this._locale);
          });
        }
        return this._locale;
      },
      get: function get() {
        return Ember.get(this, '_locale');
      }
    }),
    /**
     * Returns the first locale of the currently active locales
     *
     * @property primaryLocale
     * @public
     */
    primaryLocale: Ember.computed.readOnly('locale.0'),
    /** @public **/
    formatRelative: createFormatterProxy('relative'),
    /** @public **/
    formatMessage: createFormatterProxy('message'),
    /** @public **/
    formatNumber: createFormatterProxy('number'),
    /** @public **/
    formatTime: createFormatterProxy('time'),
    /** @public **/
    formatDate: createFormatterProxy('date'),
    /** @private **/
    _translationContainer: null,
    /** @private **/
    _locale: null,
    /** @private **/
    _timer: null,
    /** @private **/
    _formatters: null,
    /** @public **/init: function init() {
      this._super.apply(this, arguments);
      var initialLocale = Ember.get(this, 'locale') || ['en-us'];
      this.setLocale(initialLocale);
      this._owner = Ember.getOwner(this);
      // Below issue can be ignored, as this is during the `init()` constructor.
      // eslint-disable-next-line ember/no-assignment-of-untracked-properties-used-in-tracking-contexts
      this._translationContainer = _container.default.create();
      this._formatters = this._createFormatters();
      if (!this.formats) {
        this.formats = this._owner.resolveRegistration('formats:main') || {};
      }
      (0, _hydrate.default)(this);
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);
      Ember.run.cancel(this._timer);
    },
    /** @private **/onError: function onError(_ref) {
      var error = _ref.error;
      throw error;
    },
    /** @public **/lookup: function lookup(key, localeName) {
      var localeNames = this._localeWithDefault(localeName);
      var translation;
      for (var i = 0; i < localeNames.length; i++) {
        translation = this._translationContainer.lookup(localeNames[i], key);
        if (translation !== undefined) {
          break;
        }
      }
      return translation;
    },
    /** @private **/lookupAst: function lookupAst(key, localeName) {
      var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      var localeNames = this._localeWithDefault(localeName);
      var translation;
      for (var i = 0; i < localeNames.length; i++) {
        translation = this._translationContainer.lookupAst(localeNames[i], key);
        if (translation !== undefined) {
          break;
        }
      }
      if (translation === undefined && options.resilient !== true) {
        var missingMessage = this._owner.resolveRegistration('util:intl/missing-message');
        return missingMessage.call(this, key, localeNames, options);
      }
      return translation;
    },
    validateKeys: function validateKeys(keys) {
      return keys.forEach(function (key) {
        (true && !(typeof key === 'string') && Ember.assert("[ember-intl] expected translation key \"".concat(key, "\" to be of type String but received: \"").concat((0, _typeof2.default)(key), "\""), typeof key === 'string'));
      });
    },
    /** @public **/t: function t(key) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      var keys = [key];
      if (options.default) {
        if (Array.isArray(options.default)) {
          keys = [].concat((0, _toConsumableArray2.default)(keys), (0, _toConsumableArray2.default)(options.default));
        } else if (typeof options.default === 'string') {
          keys = [].concat((0, _toConsumableArray2.default)(keys), [options.default]);
        }
      }
      this.validateKeys(keys);
      for (var index = 0; index < keys.length; index++) {
        var _key = keys[index];
        var ast = this.lookupAst(_key, options.locale, _objectSpread(_objectSpread({}, options), {}, {
          // Note: last iteration will throw with the last key that was missing
          // in the future maybe the thrown error should include all the keys to help debugging
          resilient: keys.length - 1 !== index
        }));
        if (ast) {
          return this.formatMessage(ast, options);
        }
      }
    },
    /** @public **/exists: function exists(key, localeName) {
      var _this2 = this;
      var localeNames = this._localeWithDefault(localeName);
      (true && !(Array.isArray(localeNames) && localeNames.length) && Ember.assert("[ember-intl] locale is unset, cannot lookup '".concat(key, "'"), Array.isArray(localeNames) && localeNames.length));
      return localeNames.some(function (localeName) {
        return _this2._translationContainer.has(localeName, key);
      });
    },
    /** @public */setLocale: function setLocale(locale) {
      Ember.set(this, 'locale', locale);
    },
    /** @public **/addTranslations: function addTranslations(localeName, payload) {
      this._translationContainer.push((0, _normalizeLocale.default)(localeName), payload);
    },
    /** @public **/translationsFor: function translationsFor(localeName) {
      return this._translationContainer.findTranslationModel((0, _normalizeLocale.default)(localeName), false);
    },
    /** @private **/_localeWithDefault: function _localeWithDefault(localeName) {
      if (!localeName) {
        return Ember.get(this, '_locale') || [];
      }
      if (typeof localeName === 'string') {
        return Ember.makeArray(localeName).map(_normalizeLocale.default);
      }
      if (Array.isArray(localeName)) {
        return localeName.map(_normalizeLocale.default);
      }
    },
    /** @private **/_updateDocumentLanguage: function _updateDocumentLanguage(locales) {
      var dom = (0, _getDom.default)(this);
      if (dom) {
        var _locales = (0, _slicedToArray2.default)(locales, 1),
          primaryLocale = _locales[0];
        var html = dom.documentElement;
        html.setAttribute('lang', primaryLocale);
      }
    },
    /** @private */_createFormatters: function _createFormatters() {
      var _this3 = this;
      var formatterConfig = {
        onError: this.onError.bind(this),
        readFormatConfig: function readFormatConfig() {
          return _this3.formats;
        }
      };
      return {
        message: new _formatters.FormatMessage(formatterConfig),
        relative: new _formatters.FormatRelative(formatterConfig),
        number: new _formatters.FormatNumber(formatterConfig),
        time: new _formatters.FormatTime(formatterConfig),
        date: new _formatters.FormatDate(formatterConfig)
      };
    }
  });
  function createFormatterProxy(name) {
    return function serviceFormatterProxy(value, formatOptions) {
      var locale;
      if (formatOptions && formatOptions.locale) {
        locale = this._localeWithDefault(formatOptions.locale);
      } else {
        locale = Ember.get(this, 'locale');
      }
      return this._formatters[name].format(locale, value, formatOptions);
    };
  }
});