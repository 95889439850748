define("portal-toolkit/components/charts/line-chart", ["exports", "ember-c3/components/c3-chart", "portal-toolkit/helpers/format-number", "moment"], function (_exports, _c3Chart, _formatNumber, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _c3Chart.default.extend({
    classNames: ['chart', 'chart--line'],
    data: Ember.computed('metrics', 'series', 'period', function () {
      var periodType = Ember.get(this, 'period.type');
      var dates = [];
      var seriesMeta = Ember.get(this, 'series');
      var metrics = Ember.get(this, 'metrics');
      var series = Ember.isArray(metrics) ? metrics[0].series : metrics.series;
      var columns = [];

      if (!series) {
        (true && Ember.warn('No series provided to line chart component.'));
        return;
      }

      var start = (0, _moment.default)(Ember.get(this, 'period.start'), 'YYYY-MM').endOf('month').toDate();
      var end = (0, _moment.default)(Ember.get(this, 'period.end'), 'YYYY-MM').endOf('month').toDate();
      var currentPeriod = start;

      while (currentPeriod <= end) {
        dates.push(currentPeriod);
        currentPeriod = (0, _moment.default)(currentPeriod).add(1, 'month').endOf('month').toDate();
      }

      seriesMeta.forEach(function (meta, seriesIndex) {
        var thisSeries = series.find(function (s) {
          return s.entity === Ember.get(meta, 'id') || s.group === Ember.get(meta, 'id');
        }) || {};
        var periods = thisSeries.periods || [];
        columns.pushObject([Ember.get(meta, 'name')]);
        dates.forEach(function (date, periodIndex) {
          var period = periods.find(function (p) {
            return p.date === date.toISOString();
          });
          var value = period ? Ember.get(period, "periodTypes.".concat(periodType, ".value")) : null;
          columns[seriesIndex][periodIndex + 1] = value == null ? null : value;
          currentPeriod = (0, _moment.default)(currentPeriod).add(1, 'month').endOf('month').toDate();
        });
      });
      columns.unshift(['Periods'].concat(dates));
      return {
        x: 'Periods',
        columns: columns
      };
    }),
    axis: Ember.computed('metrics', function () {
      var metrics = Ember.get(this, 'metrics');
      var meta = Ember.isArray(metrics) ? metrics[0].meta : metrics.meta;
      var label;
      var reduction;
      var yAxisPlaces = 0;
      var periodType = Ember.get(this, 'period.type');

      if (meta.format === 'PERCENTAGE') {
        label = meta.name;
      } else {
        var series = Ember.isArray(metrics) ? metrics[0].series : metrics.series;
        var longest = 0;
        series.forEach(function (_ref) {
          var periods = _ref.periods;
          periods.forEach(function (period) {
            var current = period ? Ember.get(period, "periodTypes.".concat(periodType, ".value")) : null;

            if (current > longest) {
              longest = current;
            }
          });
        });
        var longestLength = Math.floor(longest).toString().length;
        var setsOfThree = Math.ceil(longestLength / 3) - 1;
        reduction = Math.pow(10, setsOfThree * 3);
        var reductionText;
        yAxisPlaces = longestLength % 3 === 1 ? 3 - longestLength : 0;

        switch (setsOfThree) {
          case 1:
            reductionText = 'thousands';
            break;

          case 2:
            reductionText = 'millions';
            break;

          case 3:
            reductionText = 'billions';
            break;

          case 4:
            reductionText = 'trillions';
            break;

          default:
            if (setsOfThree) {
              reductionText = '⨉ ' + reduction;
            }

            break;
        }

        if (reductionText) {
          label = "".concat(meta.name, " (").concat(reductionText, ")");
        } else {
          label = meta.name;
        }
      }

      return {
        x: {
          type: 'timeseries',
          tick: {
            format: function format(tick) {
              return (0, _moment.default)(tick).format('MMM-YY');
            }
          }
        },
        y: {
          label: {
            text: label,
            position: 'outer-middle'
          },
          tick: {
            format: function format(tick) {
              if (meta.format === 'PERCENTAGE') {
                return (0, _formatNumber.formatNumber)(['percentage', tick], {
                  places: 2,
                  dashZero: false
                });
              } else {
                return (0, _formatNumber.formatNumber)(tick / reduction, {
                  places: yAxisPlaces,
                  dashZero: false
                });
              }
            }
          }
        }
      };
    }),
    size: {
      height: 220
    },
    grid: {
      x: {
        show: false
      },
      y: {
        show: true
      }
    },
    tooltip: Ember.computed('metrics', function () {
      var metrics = Ember.get(this, 'metrics');
      var meta = Ember.isArray(metrics) ? metrics[0].meta : metrics.meta;
      return {
        show: true,
        format: {
          title: function title(date) {
            return (0, _moment.default)(date).format('MMMM YYYY');
          },
          value: function value(_value) {
            if (meta.format === 'PERCENTAGE') {
              return (0, _formatNumber.formatNumber)(['percentage', _value], {
                sigfigs: 4,
                dashZero: false
              });
            } else if (meta.format === 'CURRENCY') {
              return (0, _formatNumber.formatNumber)(['currency', _value], {
                places: _value >= 100 ? 0 : 2,
                dashZero: false
              });
            } else {
              var numberLength = Math.floor(_value).toString().length;
              return (0, _formatNumber.formatNumber)(_value, {
                places: numberLength > 3 ? 0 : 3 - numberLength,
                dashZero: false
              });
            }
          }
        }
      };
    }),
    legend: {
      position: 'bottom'
    }
  });

  _exports.default = _default;
});