define("portal-toolkit/components/charts/gauge-chart", ["exports", "ember-c3/components/c3-chart", "portal-toolkit/helpers/format-number"], function (_exports, _c3Chart, _formatNumber) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _c3Chart.default.extend({
    classNames: ['chart', 'chart--gauge'],
    reverseColors: false,
    min: 0,
    max: 1,
    label: '% of target',
    data: Ember.computed('metrics', 'period', 'target', function () {
      var metrics = Ember.get(this, 'metrics');
      var target = Ember.get(this, 'target');
      var periodType = Ember.get(this, 'period.type');
      var columns = [];

      if (!metrics || Ember.isEmpty(metrics)) {
        (true && Ember.warn('No data provided to gauge chart component.'));
        return;
      }

      var name = Ember.get(metrics[0], 'meta.name');
      var metric = Ember.get(metrics[0], "series.0.periods.0.periodTypes.".concat(periodType, ".value")) || 0;
      var value = metric / target;
      columns.push([name, value]);
      return {
        type: 'gauge',
        columns: columns
      };
    }),
    size: {
      height: 180
    },
    gauge: Ember.computed('min', 'max', 'units', function () {
      return {
        label: {
          format: function format(value, ratio) {
            return (0, _formatNumber.formatNumber)(['percentage', value], {
              places: 2,
              dashZero: false
            });
          },
          show: true // to turn off the min/max labels

        },
        min: Ember.get(this, 'min'),
        max: Ember.get(this, 'max'),
        // 100 is default
        units: Ember.get(this, 'label'),
        width: 60 // for adjusting arc thickness

      };
    }),
    color: Ember.computed('min', 'max', function () {
      var min = Ember.get(this, 'min');
      var max = Ember.get(this, 'max');
      var reverseColors = Ember.get(this, 'reverseColors');
      var values = [];
      var colors = ['#ca1528', '#d02329', '#db382b', '#e6502e', '#ef6931', '#f08033', '#f09136', '#d0973c', '#b0973e', '#8c9341', '#688c44', '#498646'];

      if (reverseColors) {
        colors = colors.reverse();
      }

      for (var i = 0; i < colors.length; i++) {
        values.push(min + i / colors.length * (max - min));
      }

      return {
        pattern: colors,
        threshold: {
          unit: 'value',
          // percentage is default
          max: Ember.get(this, 'max'),
          // 100 is default
          values: values
        }
      };
    }),
    legend: {
      position: 'right'
    }
  });

  _exports.default = _default;
});