define("portal-toolkit/services/cache", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    _cache: {},
    store: function store(cacheName, key, value) {
      (true && !(typeof cacheName === 'string') && Ember.assert('A cache name is required.', typeof cacheName === 'string'));
      (true && !(key != null) && Ember.assert('A key is required.', key != null));
      var cachePath = "_cache.".concat(cacheName);
      var cache = Ember.get(this, cachePath);

      if (!cache || !Ember.isArray(cache)) {
        Ember.set(this, cachePath, []);
        cache = Ember.get(this, cachePath);
      }

      var existing = cache.find(function (obj) {
        return _lodash.default.isEqual(Ember.get(obj, 'key'), key);
      });

      if (existing) {
        Ember.set(existing, 'value', value);
      } else {
        cache.pushObject({
          key: key,
          value: value
        });
      }
    },
    lookup: function lookup(cacheName, key) {
      (true && !(typeof cacheName === 'string') && Ember.assert('A cache name is required.', typeof cacheName === 'string'));
      (true && !(key != null) && Ember.assert('A key is required.', key != null));
      var cache = Ember.get(this, "_cache.".concat(cacheName));

      if (!cache) {
        return undefined;
      }

      var record = cache.find(function (obj) {
        return _lodash.default.isEqual(Ember.get(obj, 'key'), key);
      });
      return record ? Ember.get(record, 'value') : undefined;
    },
    clear: function clear(cacheName, key) {
      (true && !(typeof cacheName === 'string') && Ember.assert('A cache name is required.', typeof cacheName === 'string'));
      var cache = Ember.get(this, '_cache.' + cacheName);

      if (!cache) {
        return false;
      }

      if (Ember.isEmpty(key)) {
        Ember.set(this, "_cache.".concat(cacheName), []);
        return true;
      }

      var record = cache.find(function (obj) {
        return _lodash.default.isEqual(Ember.get(obj, 'key'), key);
      });

      if (record) {
        cache.removeObject(record);
      }

      return Boolean(record);
    }
  });

  _exports.default = _default;
});