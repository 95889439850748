define("portal-toolkit/services/notifications", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    list: [],
    defaultClearDuration: 3500,
    info: function info() {
      return this.showProxy.apply(this, ['info'].concat(Array.prototype.slice.call(arguments)));
    },
    warning: function warning() {
      return this.showProxy.apply(this, ['warning'].concat(Array.prototype.slice.call(arguments)));
    },
    success: function success() {
      return this.showProxy.apply(this, ['success'].concat(Array.prototype.slice.call(arguments)));
    },
    error: function error() {
      return this.showProxy.apply(this, ['error'].concat(Array.prototype.slice.call(arguments)));
    },
    showProxy: function showProxy(type, options, duration) {
      options = options || {};

      if (typeof options === 'string') {
        options = {
          message: options
        };
      }

      options.type = type;
      return this.show(options, duration);
    },
    show: function show(options) {
      var duration = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : Ember.get(this, 'defaultClearDuration');
      options = options || {}; // Support show('Message')

      if (typeof options === 'string') {
        options = {
          message: options
        };
      }

      var _options = options,
          message = _options.message,
          _options$type = _options.type,
          type = _options$type === void 0 ? 'info' : _options$type,
          _options$autoClear = _options.autoClear,
          autoClear = _options$autoClear === void 0 ? arguments.length > 1 || options.type === 'success' : _options$autoClear,
          _options$clearDuratio = _options.clearDuration,
          clearDuration = _options$clearDuratio === void 0 ? duration : _options$clearDuratio; // Check that a message has been set

      (true && !(message) && Ember.assert('A notification message must be set.', message));
      var notification = Ember.Object.create({
        message: message,
        type: type,
        autoClear: autoClear,
        clearDuration: clearDuration
      });
      Ember.get(this, 'list').pushObject(notification);

      if (Ember.get(notification, 'autoClear')) {
        this.setupAutoClear(notification);
      }

      return notification;
    },
    setupAutoClear: function setupAutoClear(notification) {
      var _this = this;

      /*
      TODO There is currently no modules friendly approach to this.
      Apparently its being worked on but I can't find where.
      It should be reviseted at a later date.
      */
      if (Ember.testing) return;
      Ember.run.later(function () {
        // Hasn't been closed manually
        if (Ember.get(_this, 'list').indexOf(notification) !== -1 && !Ember.get(notification, 'dismiss')) {
          _this.clear(notification);
        }
      }, Ember.get(notification, 'clearDuration'));
    },
    clear: function clear(notification) {
      var _this2 = this;

      (true && !(notification) && Ember.assert('The notification to clear must be provided.', notification));
      Ember.set(notification, 'dismiss', true); // Delay removal from DOM for dismissal animation

      Ember.run.later(function () {
        return Ember.get(_this2, 'list').removeObject(notification);
      }, 500);
    },
    clearAll: function clearAll() {
      var _this3 = this;

      Ember.get(this, 'list').forEach(function (notification) {
        return _this3.clear(notification);
      });
    }
  });

  _exports.default = _default;
});